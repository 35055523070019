import { defineMessages } from 'react-intl';

import { TaskGroupStatus } from '../queries/api/types';
import { ImportFileErrorsCodes } from '../queries/taskGroups';

const taskGroupMessages = defineMessages({
    allStatuses: {
        id: 'ot_messages.all_statuses',
        defaultMessage: 'Tout les statuts',
        description: 'All statuses',
    },
    toPlan: {
        id: 'ot_messages.to_plan',
        defaultMessage: 'À planifier',
        description: 'To plan',
    },
    readyToStart: {
        id: 'ot_messages.ready_to_start',
        defaultMessage: 'Prêt à démarrer',
        description: 'Ready to start',
    },
    inProgress: {
        id: 'ot_messages.in_progress',
        defaultMessage: 'En cours',
        description: 'In progress',
    },
    done: {
        id: 'ot_messages.done',
        defaultMessage: 'Terminée',
        description: 'Done',
    },
    toClose: {
        id: 'ot_messages.to_close',
        defaultMessage: 'À clôturer',
        description: 'To close',
    },
    external: {
        id: 'ot_messages.external',
        defaultMessage: '{unitDisplay, select, long {Externe} narrow {Ext.} other {Externe}}',
        description: 'External',
    },
    pending: {
        id: 'ot_messages.pending',
        defaultMessage: 'En attente',
        description: 'Pending',
    },
    transferred: {
        id: 'ot_messages.transferred',
        defaultMessage: 'Transféré',
        description: 'Pending',
    },
    desiredVehicle: {
        id: 'ot_messages.desired_vehicle',
        defaultMessage: 'Véhicule souhaité',
        description: 'Desired vehicle',
    },
    start: {
        id: 'ot_messages.start',
        defaultMessage: 'Départ',
        description: 'Start',
    },
    end: {
        id: 'ot_messages.end',
        defaultMessage: 'Arrivée',
        description: 'End',
    },
    taskCount: {
        id: 'ot_messages.task_count',
        defaultMessage: 'Points de livraison',
        description: 'Task count',
    },
    expectedMileage: {
        id: 'ot_messages.expected_mileage',
        defaultMessage: `{count, plural, =0 {KM prévu} one {KM prévu} other {Kilométrage prévu}}`,
        description: 'Expected mileage',
    },
    pdl: {
        id: 'ot_messages.pdl',
        defaultMessage: 'PDL',
        description: 'PDL',
    },
    volume: {
        id: 'ot_messages.volume',
        defaultMessage: 'Volume',
        description: 'Volume',
    },
    weight: {
        id: 'ot_messages.weight',
        defaultMessage: 'Poids',
        description: 'Weight',
    },
    showCalendarButton: {
        id: 'ot_messages.show_calendar.button',
        defaultMessage: 'Afficher le calendrier',
        description: 'Show calendar',
    },
    importFileButton: {
        id: 'ot_messages.import_file.button',
        defaultMessage: 'Importer un fichier',
        description: 'Import a file',
    },
    calendarTaskGroups: {
        id: 'ot_messages.calendar_task_groups',
        defaultMessage: 'Calendrier des OT',
        description: 'Task groups calendar',
    },
    importFileSuccess: {
        id: 'ot_messages.import_file.success',
        defaultMessage: 'Fichier importé avec succès',
    },
    cantImportFileModalTitle: {
        id: 'ot_messages.import_file.modal.cantImportModal.title',
        defaultMessage: 'Import du fichier impossible',
    },
    cantImportFileModalPlaceCoherenceErrorContent: {
        id: 'ot_messages.import_file.modal.cantImportModal.placeCoherenceErrorContent',
        defaultMessage:
            'Le fichier n’a pas pu être importé car l’entrepôt sélectionné ne correspond pas à celui présent dans le fichier.',
    },
    cantImportFileModalCustomerCoherenceErrorContent: {
        id: 'ot_messages.import_file.modal.cantImportModal.customerCoherenceErrorContent',
        defaultMessage: 'Le fichier importé ne correspond pas au client sélectionné.',
    },
    cantImportFileModalfileNameNotSupportedContent: {
        id: 'ot_messages.import_file.modal.cantImportModal.fileNameNotSupportedContent',
        defaultMessage: 'Le nom du fichier ne correspond pas au format attendu.',
    },
    importFileError: {
        id: 'ot_messages.import_file.error',
        defaultMessage: 'Le format du fichier doit correspondre à un .xls ou .xlsx',
    },
    vehicleSelectorLabel: {
        id: 'ot_messages.vehicle_selector.label',
        defaultMessage: 'Véhicule à affecter',
        description: 'ot vehicle selector label',
    },
    vehicleSelectorPlaceholder: {
        id: 'ot_messages.vehicle_selector.placeholder',
        defaultMessage: 'Sélectioner un véhicule disponible',
        description: 'ot vehicle selector placeholder',
    },
    operatorSelectorLabel: {
        id: 'ot_messages.operator_selector.label',
        defaultMessage: 'Chauffeur à affecter',
        description: 'ot operator selector label',
    },
    operatorSelectorPlaceholder: {
        id: 'ot_messages.operartor_selector.placeholder',
        defaultMessage: 'Sélectioner un chauffeur disponible',
        description: 'ot operator selector placeholder',
    },
    confirmPlanification: {
        id: 'ot_messages.confirm_planification',
        defaultMessage: 'Valider la planification',
    },
    confirmPlanificationModalTitle: {
        id: 'ot_messages.confirm_planification_modal.title',
        defaultMessage: "Planification de l'OT",
    },
    confirmPlanificationModalContent: {
        id: 'ot_messages.confirm_planification_modal.content',
        defaultMessage: 'Êtes-vous sur de vouloir planifier cet OT avec le chauffeur et le véhicule sélectionné ?',
    },
    confirmAutoPlanificationModalTitle: {
        id: 'ot_messages.confirm_auto_planification_modal.title',
        defaultMessage: `{count, plural, =0 {Planification de l'OT} one {Planification de l'OT} other {Planification des OT}}`,
    },
    confirmAutoPlanificationModalContent: {
        id: 'ot_messages.confirm_auto_planification_modal.content',
        defaultMessage: `{count, plural, =0 {Êtes-vous sur de vouloir valider la planification de cet OT ?} one {Êtes-vous sur de vouloir valider la planification de cet OT ?} other {Êtes-vous sur de vouloir valider la planification de ces OT ?}}`,
    },
    partnerPrestationModalTitle: {
        id: 'ot_messages.partner_service_modal.title',
        defaultMessage: 'Prestation partenaire',
    },
    partnerPrestationModalSelectorLabel: {
        id: 'ot_messages.partner_service_modal.selector_label',
        defaultMessage: 'Prestation du partenaire associé à cet OT',
    },
    partnerPrestationModalSelectorPlaceholder: {
        id: 'ot_messages.partner_service_modal.selector_placeholder',
        defaultMessage: 'Sélectioner une prestation',
    },
    planificationSuccess: {
        id: 'ot_messages.planification_success',
        defaultMessage: 'Tournée planifiée avec succès',
    },
    cantPlan: {
        id: 'ot_messages.cant_plan',
        defaultMessage: "Planification de l'OT impossible",
    },
    operatorUnavailableError: {
        id: 'ot_messages.operartor_unavailable',
        defaultMessage:
            "Le chauffeur sélectionné n'est plus disponible pour cet OT. Veuillez modifier la planification.",
    },
    vehicleUnavailableError: {
        id: 'ot_messages.vehicle_unavailable',
        defaultMessage:
            "Le véhicule sélectionné n'est plus disponible pour cet OT. Veuillez modifier la planification.",
    },
    noBudgetTooltip: {
        id: 'ot_messages.no_budget_tootltip',
        defaultMessage: 'Ce client n’a pas de données prévisionnelles budgétaires.',
    },
    transferTaskGroup: {
        id: 'ot_messages.transfer_taskgroup',
        defaultMessage: 'Transférer l’OT',
    },
    autoPlanificationSuccess: {
        id: 'ot_messages.auto_planification.success',
        defaultMessage: 'Propositions de planification faites avec succès',
    },
    removeBatchConfirmationModalTitle: {
        id: 'ot_messages.remove_batch_confirmation_modal.title',
        defaultMessage: 'Suppression d’OT',
    },
    removeBatchConfirmationModalText: {
        id: 'ot_messages.remove_batch_confirmation_modal.text',
        defaultMessage: `{count, plural, one {Êtes-vous sûr de vouloir supprimer l'OT sélectionné ?} other {Êtes-vous sûr de vouloir supprimer les OT sélectionnés ?}}`,
    },
    removeBatchSuccess: {
        id: 'ot_messages.remove_batch.success',
        defaultMessage: `{count, plural, one {OT supprimé avec succès} other {OT supprimés avec succès}}`,
    },
    completeProposals: {
        id: 'ot_messages.auto_planification.successfullProposals',
        defaultMessage: 'Tournées planifiées à valider',
    },
    incompleteProposals: {
        id: 'ot_messages.auto_planification.unsuccessfullProposals',
        defaultMessage: 'Tournées non planifiées',
    },
    suggestedVehicle: {
        id: 'ot_messages.auto_planification.suggested_vehicle',
        defaultMessage: 'Véhicule proposé',
        description: 'Suggested vehicle',
    },
    suggestedOperator: {
        id: 'ot_messages.auto_planification.suggested_operator',
        defaultMessage: 'Chauffeur proposé',
        description: 'Suggested operator',
    },
    missingOperators: {
        id: 'ot_messages.auto_planification.missingOperators',
        defaultMessage: 'Chauffeurs manquants: {count}',
    },
    missingVehicles: {
        id: 'ot_messages.auto_planification.missingVehicles',
        defaultMessage: 'Véhicules manquants: {count}',
    },
    planificationInProgess: {
        id: 'ot_messages.auto_planification.planificationInProgess',
        defaultMessage: 'Planification en cours...',
    },
    planificationSuccessful: {
        id: 'ot_messages.auto_planification.successfullyApplied',
        defaultMessage: 'OT planifiés avec succès',
    },
    proposalsAppliedWithErrorsTitle: {
        id: 'ot_messages.auto_planification.proposalsAppliedWithErrorsTitle',
        defaultMessage: 'OT planifiés avec des erreurs',
    },
    proposalsAppliedWithErrorsContent: {
        id: 'ot_messages.auto_planification.proposalsAppliedWithErrorsContent',
        defaultMessage:
            'Les OT suivants n’ont pas pu être planifiés car des ressources n’étaient plus disponibles au moment de la validation:',
    },
    closeSelectedOTModalTitle: {
        id: 'ot_messages.external.closeSelectedTaskGroupsModalTitle',
        defaultMessage: 'Clôture des OT',
        description: 'Close selected TaskGroups modal title',
    },
    closeSelectedOTModalContent: {
        id: 'ot_messages.external.closeSelectedTaskGroupsModalContent',
        defaultMessage: 'Êtes-vous sur de vouloir clôturer les OT sélectionnés ?',
        description: 'Close selected TaskGroups modal content',
    },
    closeSelectedOTSuccessMessage: {
        id: 'ot_messages.external.closeSelectedTaskGroupsSuccessMessage',
        defaultMessage: 'OT clôturés avec succès',
        description: 'Close selected TaskGroups success message',
    },
});

export const taskGroupCaptionMessagesMap = new Map([
    [TaskGroupStatus.readyToStart, taskGroupMessages.readyToStart],
    [TaskGroupStatus.toPlan, taskGroupMessages.toPlan],
    [TaskGroupStatus.inProgress, taskGroupMessages.inProgress],
    [TaskGroupStatus.done, taskGroupMessages.done],
    [TaskGroupStatus.toClose, taskGroupMessages.toClose],
]);

export const importFileErrorMessagesMap = new Map([
    [ImportFileErrorsCodes.placeCoherenceError, taskGroupMessages.cantImportFileModalPlaceCoherenceErrorContent],
    [ImportFileErrorsCodes.customerCoherenceError, taskGroupMessages.cantImportFileModalCustomerCoherenceErrorContent],
    [ImportFileErrorsCodes.fileNameNotSupported, taskGroupMessages.cantImportFileModalfileNameNotSupportedContent],
]);

export default taskGroupMessages;
